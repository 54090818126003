<template>
  <div class="blog-wrapper p-1">
    <MenuComponent />
    <br />
    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <content-with-sidebar class="blog-wrapper">
          <!-- content -->
          <div class="blog-detail-wrapper">
            <b-row>
              <!-- blogs -->
              <b-col cols="12">
                <b-card
                  :img-src="getUrl + 'penyimpanan/blog/' + dataBlog.photo"
                  img-top
                >
                  <b-media no-body>
                    <b-link class="brand-logo" href="/">
                      <b-img
                        :src="require('@/assets/images/avatars/13-small.png')"
                        style="width: 20px; height: 20px"
                        alt="logo"
                        class=""
                      ></b-img>
                    </b-link>
                    <b-media-body>
                      <small class="text mr-50"> by</small>
                      <small>
                        <b-link class="text-body">Optimal</b-link>
                      </small>
                      <span class="text-muted ml-75 mr-50">|</span>
                      <small class="text">{{
                        dataBlog.created_at.substring(0, 10)
                      }}</small>
                    </b-media-body>
                  </b-media>
                  <div class="my-1 py-25">
                    <b-link>
                      <b-badge pill class="mr-75" variant="info">
                        Umum
                      </b-badge>
                    </b-link>
                    <b-link>
                      <b-badge pill class="mr-75" variant="success">
                        Kesehatan
                      </b-badge>
                    </b-link>
                    <b-link>
                      <b-badge pill class="mr-75" variant="warning">
                        Layanan
                      </b-badge>
                    </b-link>
                    <b-link>
                      <b-badge pill class="mr-75" variant="primary">
                        Tips
                      </b-badge>
                    </b-link>
                    <b-link>
                      <b-badge pill class="mr-75" variant="danger">
                        Medis
                      </b-badge>
                    </b-link>
                  </div>
                  <!-- eslint-disable vue/no-v-html -->
                  <div class="blog-content" v-html="dataBlog.content" />
                </b-card>
              </b-col>
              <!--/ blogs -->
            </b-row>
            <!--/ blogs -->
          </div>
          <!--/ content -->

          <!-- sidebar -->
          <!-- sidebar -->
          <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
            <!-- input search -->
            <b-form-group class="blog-search">
              <b-input-group class="input-group-merge">
                <b-form-input id="search-input" placeholder="Cari Disini" />
                <b-input-group-append class="cursor-pointer" is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!--/ input search -->

            <!-- recent posts -->
            <div class="blog-recent-posts mt-3">
              <h6 class="mb-75">Lihat Paket Juga</h6>
              <b-media no-body v-for="paket in datapaket" v-bind:key="paket.id">
                <b-media-aside class="mr-2 mb-75">
                  <b-link>
                    <b-img
                      :src="getUrl + 'penyimpanan/paket/' + paket.photo"
                      width="50px"
                      rounded
                    />
                  </b-link>
                </b-media-aside>
                <b-media-body>
                  <h6 class="blog-recent-post-title">
                    <b-link class="text-body-heading">
                      {{ paket.title }}
                    </b-link>
                  </h6>
                  <span class="text mb-0"
                    >Rp.{{ formatPrice(paket.price) }}
                  </span>
                  <p></p>
                </b-media-body>
              </b-media>
            </div>
            <!--/ recent posts -->

            <!-- categories -->
            <div class="blog-categories mt-3">
              <h6 class="mb-1">Kategori</h6>

              <div
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link>
                  <b-avatar rounded size="32" variant="info" class="mr-75">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-link>
                  <div class="blog-category-title text-body">Umum</div>
                </b-link>
              </div>
              <div
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link>
                  <b-avatar rounded size="32" variant="success" class="mr-75">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-link>
                  <div class="blog-category-title text-body">Kesehatan</div>
                </b-link>
              </div>
              <div
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link>
                  <b-avatar rounded size="32" variant="warning" class="mr-75">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-link>
                  <div class="blog-category-title text-body">Layanan</div>
                </b-link>
              </div>
              <div
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link>
                  <b-avatar rounded size="32" variant="primary" class="mr-75">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-link>
                  <div class="blog-category-title text-body">Tips</div>
                </b-link>
              </div>
              <div
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link>
                  <b-avatar rounded size="32" variant="danger" class="mr-75">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-link>
                  <div class="blog-category-title text-body">Medis</div>
                </b-link>
              </div>
            </div>
            <!--/ categories -->
          </div>
        </content-with-sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import MenuComponent from "@/views/Menu.vue";
export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    MenuComponent,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataBlog: [],
      datapaket: [],
      selected: "left",
      selected2: "right",
      level: localStorage.getItem("Ulevel"),
      getUrl: "",
    };
  },
  async created() {
    await this.getData();
    this.getDatapaket();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async getData() {
      const id = this.$route.params.id;
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "blogs/search/" + id)
        .then((res) => {
          this.dataBlog = res.data.data;
          // console.log(this.dataBlog);
        });
    },
    async getDatapaket() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view_limit")
        .then((res) => {
          this.datapaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
